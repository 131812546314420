<template>
  <div>
    <v-container class="pa-5 mb-5">
      <v-row>
        <v-col cols="12" md="6">
          <validation-observer ref="observer"
                               v-slot="{invalid}">
            <v-form @submit.prevent="submitBusiness">
              <div class="mt-5 mx-sm-15 mx-md-0 text-center text-md-left">
                <p class="text-h6 text-sm-h5 text-md-left">¡Enhorabuena! {{ userFirstName }}<br>
                  Llegamos al siguiente paso, no pares...</p>
                <p class="mt-7 mb-4 text-h5 text-sm-h4 text-md-left" style="color: #008081;">
                  ¿Cómo se llama tu negocio?
                </p>

                <br>

                <validation-provider v-slot="{errors}"
                                     :rules="{required: true, businessName: true}">
                  <v-text-field v-model="business.name"
                                :error-messages="errors"
                                clearable
                                color="#00999a"
                                label="Escribe el nombre del negocio aquí"
                                outlined>
                  </v-text-field>
                </validation-provider>

                <v-btn :loading="loading"
                       class="mt-1 mb-7"
                       color="#de8d55"
                       dark
                       elevation="0"
                       large
                       rounded
                       type="submit">
                  Crear Negocio
                </v-btn>
              </div>
            </v-form>
          </validation-observer>
        </v-col>
        <v-col cols="12" md="6">
          <v-img :src="require('../../assets/completed-steps-animate.svg')"
                 contain
                 max-height="400">
          </v-img>
        </v-col>
      </v-row>

    </v-container>
    <Footer></Footer>
  </div>
</template>

<script>
import router from "@/router"
import services from "@/services"
import {required} from 'vee-validate/dist/rules'
import {extend, setInteractionMode, ValidationObserver, ValidationProvider} from 'vee-validate'

const Footer = () => import(/* webpackPrefetch: true */ "@/components/Footer");

setInteractionMode('eager')

extend('required', {
  ...required,
  message: 'El campo no puede estar vacio.',
})

extend("businessName", {
  message: "El nombre del negocio no es válido o no puede ser usado.",
  validate: value => {
    if (value === '')
      return false;

    return /^[a-z\d\-_\s]+$/i.test('value') !== false;
  }
});

export default {
  name: "AdminWelcome",
  data: () => ({
    date: null,
    business: {
      name: ''
    },
    loading: false,
  }),
  components: {
    Footer,
    ValidationProvider,
    ValidationObserver,
  },
  computed: {
    loggedUser() {
      return this.$store.state.auth.user
    },
    userFirstName() {
      return this.loggedUser ? this.loggedUser.full_name.split(' ')[0] : '';
    }
  },
  methods: {
    /**
     *
     * @returns {Promise<void>}
     */
    submitBusiness: async function () {
      const validForm = await this.$refs.observer.validate()
      if (!validForm)
        return;

      try {

        this.loading = true;

        const result = await services.business.createBusiness(this.business)
        if (result && result.data && result.data.id) {
          await this.$store.dispatch('auth/updateBusiness', result.data);
          await router.push({name: 'admin_business_edit_business', params: {slug: result.data.slug}});
        } else {
          this.$dialog.notify.error('No se ha podido crear el negocio.', {
            position: 'bottom-right',
            timeout: 5000,
          })
        }
      } catch (error) {
        console.log(error);
        this.$dialog.notify.error('No se ha podido crear el negocio.', {
          position: 'bottom-right',
          timeout: 5000,
        })
      } finally {
        this.loading = false;
      }
    },
  },
  async beforeRouteEnter(to, from, next) {
    const slug = to.params.slug;
    if (!slug) {
      try {
        let result = await services.business.getMyBusiness();
        if (result && result.data) {
          const business = result.data;
          if (business.length > 0) {
            await router.push({name: 'admin_business_edit_business', params: {slug: business[0].slug}});
          } else {
            next();
          }
        } else {
          next('/error-internal');
        }
      } catch (error) {
        next('/error-internal');
      }
    } else {
      next();
    }
  },
}
</script>

<style scoped>
/*region SVG Animation*/
svg#freepik_stories-completed-steps:not(.animated) .animable {
  opacity: 0;
}

svg#freepik_stories-completed-steps.animated #freepik--background-complete--inject-72 {
  animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideUp;
  animation-delay: 0s;
}

svg#freepik_stories-completed-steps.animated #freepik--Floor--inject-72 {
  animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) fadeIn;
  animation-delay: 0s;
}

svg#freepik_stories-completed-steps.animated #freepik--Plant--inject-72 {
  animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideRight;
  animation-delay: 0s;
}

svg#freepik_stories-completed-steps.animated #freepik--Character--inject-72 {
  animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideUp;
  animation-delay: 0s;
}

svg#freepik_stories-completed-steps.animated #freepik--Screen--inject-72 {
  animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) fadeIn, 3s Infinite linear floating;
  animation-delay: 0s, 1s;
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    transform: inherit;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slideRight {
  0% {
    opacity: 0;
    transform: translateX(30px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes floating {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

/*endregion*/

</style>
