<template>
  <div>
    <v-overlay :value="!pageLoaded">
      <loading-general></loading-general>
    </v-overlay>
    <v-container class="py-5 px-5">
      <v-row>
        <v-col cols="12" sm="6">
          <!-- region Title -->
          <h2>Mi negocio</h2>
          <p class="text-subtitle-1 mb-0">Aquí podrás ver y actualizar toda la información.</p>
          <!-- endregion -->
        </v-col>
        <v-col cols="12" sm="6">
          <!-- region Buttons share and URL -->
          <div class="text-sm-right pt-sm-3">
            <!-- Buttons share-->
            <v-btn class="text-none mr-2 mb-2"
                   color="primary"
                   depressed
                   large
                   rounded
                   @click="dialogShare = true">
              <v-icon left>
                mdi-export-variant
              </v-icon>
              Compartir
            </v-btn>
            <v-dialog v-model="dialogShare"
                      width="510">
              <v-card class="rounded-lg pb-5">
                <div class="text-right pt-3 pr-3">
                  <v-btn color="black"
                         icon
                         @click="dialogShare = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
                <v-card-title class="text-h5 pt-0 pb-5">
                  Comparte tu negocio
                </v-card-title>

                <v-card-text>
                  <v-btn
                      :href="'https://www.facebook.com/sharer/sharer.php?u=https://api.seoferta.net/share/'+ selectedBusiness.slug"
                      class="text-none ma-1"
                      depressed large rounded target="_blank">
                    <v-icon class="mr-3" color="#3b5998" large left>mdi-facebook</v-icon>
                    Facebook
                  </v-btn>
                  <v-btn :href="'whatsapp://send?text=' + 'https://api.seoferta.net/share/'+ selectedBusiness.slug"
                         class="text-none ma-1"
                         data-action="share/whatsapp/share"
                         depressed
                         large link rounded target="_blank">
                    <v-icon class="mr-3" color="#00bb2d" large left>mdi-whatsapp</v-icon>
                    Whatsapp
                  </v-btn>
                  <v-btn :href="'tg://msg_url?url=' + 'https://api.seoferta.net/share/'+selectedBusiness.slug"
                         class="text-none ma-1"
                         depressed
                         large link rounded target="_blank">
                    <svg aria-hidden="true"
                         class="pr-1"
                         height="35"
                         preserveAspectRatio="xMidYMid meet"
                         role="img"
                         style="margin-left: -15px;"
                         viewBox="0 0 24 24"
                         width="36" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <path
                          d="M20.665 3.717l-17.73 6.837c-1.21.486-1.203 1.161-.222 1.462l4.552 1.42l10.532-6.645c.498-.303.953-.14.579.192l-8.533 7.701h-.002l.002.001l-.314 4.692c.46 0 .663-.211.921-.46l2.211-2.15l4.599 3.397c.848.467 1.457.227 1.668-.785l3.019-14.228c.309-1.239-.473-1.8-1.282-1.434z"
                          fill="#0088cc"/>
                    </svg>
                    Telegram
                  </v-btn>
                  <v-divider class="my-5"></v-divider>
                  <v-btn
                      link
                      target="_blank"
                      :href="selectedBusiness.qr_code"
                      class="text-none ma-1"
                      depressed large rounded>
                    <v-icon size="25" class="mr-3" left>mdi-qrcode-scan</v-icon>
                    Generar código QR
                  </v-btn>
                  <v-btn link
                         target="_blank"
                         :href="'https://api.seoferta.net/rsc/pdf/qr-code/' + selectedBusiness.slug"
                         class="text-none ma-1"
                         depressed large rounded>
                    <v-icon size="25" class="mr-3" left>mdi-file-star-outline</v-icon>
                    Plantilla promoción
                  </v-btn>
                </v-card-text>


              </v-card>
            </v-dialog>
            <!-- Buttons Link -->
            <v-btn :href="'/' + selectedBusiness.slug"
                   class="text-none mb-2"
                   color="secondary"
                   dark
                   depressed
                   large
                   link
                   rounded
                   target="_blank">
              <v-icon left>
                mdi-web
              </v-icon>
              Ir a mi negocio
            </v-btn>
          </div>
          <!-- endregion -->
        </v-col>
      </v-row>

      <div style="position:relative">
        <!-- region Cover -->
        <v-img :alt="selectedBusiness.slug"
               :gradient="businessCover.url !== '' ? 'to right, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)' : ''"
               :src="businessCover.url !== '' ? businessCover.url : require('@/assets/cover.jpg')"
               class="rounded-lg align-start mt-10 mb-3 text-center"
               height="200px">
          <div class="text-right pa-5">
            <v-chip class="font-weight-medium" color="white" link @click="$refs.fileCover.click()">
              Cambiar portada
            </v-chip>
            <input ref="fileCover" accept="image/*" hidden name="fileCover" type="file" @change="onFileChange"/>
          </div>
        </v-img>
        <!-- endregion-->
        <!-- region Logo -->
        <v-list-item style="position: absolute; top: 120px;">
          <v-avatar color="greyPrimary"
                    size="120"
                    style="border: 3px solid white !important;">
            <img v-if="businessLogo.url !== ''"
                 :alt="selectedBusiness.slug"
                 :lazy-src="require('../../../assets/loading.svg')"
                 :src="businessLogo.url"/>
            <span v-else
                  class="text-h3 font-weight-medium"
                  style="color: #00999a">
              {{ getFirstCharacter(selectedBusiness.name) }}
            </span>
          </v-avatar>
          <v-list-item-content class="pb-15">
            <v-list-item-action>
              <v-chip class="font-weight-medium" color="white" link @click="$refs.fileLogo.click()">Cambiar logo
              </v-chip>
            </v-list-item-action>
            <input ref="fileLogo" accept="image/*" hidden name="fileLogo" type="file" @change="onFileChange"/>
          </v-list-item-content>
        </v-list-item>
        <!--endregion-->
      </div>

      <!-- Rating -->
      <v-rating v-model="selectedBusiness.rating"
                background-color="white lighten-3"
                color="secondary"
                dense
                disabled
                readonly
                style="margin-left: 150px">
      </v-rating>

      <!-- Copy link -->
      <h3 class="font-weight-medium mt-8 mb-5">Dirección del negocio en Internet <small>(URL)</small></h3>
      <div class="d-flex pb-5">
        <input
            id="url-business-field"
            type="text"
            :value="getBusinessUrl(selectedBusiness.slug)"
            style="position: absolute; z-index: -1; left: -500px">
        <v-text-field
            :full-width="false"
            :label="getBusinessUrl(selectedBusiness.slug)"
            class=" rounded-tr-0 rounded-br-0"
            disabled
            hide-details="false"
            outlined>
        </v-text-field>
        <v-btn class="rounded-tl-0 rounded-bl-0 text-none button-copy"
               color="#bdbdbd"
               depressed
               outlined
               style="background-color: #f5f5f7"
               x-large
               @click="copyToClipboard()">
          <span style="color: #008081; font-size: 15px">Copiar</span>
          <v-icon color="primary" right>mdi-content-copy</v-icon>
        </v-btn>
      </div>

      <v-divider class="mt-3"></v-divider>

      <v-form class="" @submit.prevent="updateBusiness">
        <validation-observer ref="observer" v-slot="{invalid}">
          <!-- Main information  -->
          <h3 class="font-weight-medium my-8">Información principal</h3>
          <validation-provider v-slot="{errors}"
                               :rules="{required:true, businessName: true}">
            <v-text-field v-model="selectedBusiness.name"
                          :error-messages="errors"
                          clearable
                          color="primary"
                          label="Nombre del negocio"
                          outlined>
            </v-text-field>
          </validation-provider>
          <validation-provider v-slot="{errors}" rules="isValidPhoneNumber">
            <vue-tel-input-vuetify v-model="selectedBusiness.phone_number"
                                   :error-messages="errors"
                                   :onlyCountries="['cu']"
                                   :valid-characters-only="true"
                                   class="myPhone"
                                   clearable
                                   color="primary"
                                   defaultCountry="cu"
                                   label="Número de Teléfono"
                                   mode="international"
                                   outlined
                                   placeholder=""
                                   required>
            </vue-tel-input-vuetify>
          </validation-provider>
          <v-row>
            <v-col cols="6">
              <validation-provider v-slot="{errors}" :rules="{required:true}">
                <v-select v-model="selectedBusiness.state"
                          :error-messages="errors"
                          :item-text="'name'"
                          :items="availableStates"
                          clearable
                          color="primary"
                          label="Provincia"
                          outlined>
                </v-select>
              </validation-provider>
            </v-col>
            <v-col cols="6">
              <validation-provider v-slot="{errors}" :rules="{required:true}">
                <v-select v-model="selectedBusiness.city"
                          :error-messages="errors"
                          :items="availableCities"
                          clearable
                          color="primary"
                          label="Municipio"
                          outlined>
                </v-select>
              </validation-provider>
            </v-col>
          </v-row>
          <validation-provider v-slot="{errors}" :rules="{required:true}">
            <v-text-field v-model="selectedBusiness.address"
                          :error-messages="errors"
                          clearable
                          color="primary"
                          label="Dirección"
                          outlined>
            </v-text-field>
          </validation-provider>

          <!-- Details -->
          <v-divider class="mt-3"></v-divider>
          <h3 class="font-weight-medium my-8">Detalles del negocio
            <v-tooltip color="black" max-width="210" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    color="grey"
                    dark
                    style="font-size: 20px"
                    v-bind="attrs"
                    v-on="on"
                >
                  mdi-information-outline
                </v-icon>
              </template>
              <span>Esto es lo primero que verán los usuarios de Internet.</span>
            </v-tooltip>
          </h3>
          <v-textarea v-model="selectedBusiness.description"
                      auto-grow
                      clearable
                      color="primary"
                      label="Haz una breve descripción general de tu negocio..."
                      outlined
                      rows="4">
          </v-textarea>
          <validation-provider v-slot="{errors}" :rules="{required:true}">
            <v-select v-model="selectedBusiness.category"
                      :error-messages="errors"
                      :items="availableBusinessCategories"
                      clearable
                      color="primary"
                      label="Categoría"
                      outlined>
            </v-select>
          </validation-provider>
        </validation-observer>

        <!-- In local -->
        <!--<v-divider class="mt-2"></v-divider>-->
        <v-list-item class="px-0">
          <v-list-item-title><p class="font-weight-medium mb-1">Atención en el local
            <span class="text-subtitle-2" style="color: gray">{{
                selectedBusiness.delivery_in_business ? '(Activado)' : '(Desactivado)'
              }}</span></p>
          </v-list-item-title>
          <v-switch v-model="selectedBusiness.delivery_in_business" color="#00999a" inset>
          </v-switch>
        </v-list-item>
        <div v-if="selectedBusiness.delivery_in_business">
          <v-textarea v-model="selectedBusiness.delivery_in_business_note"
                      auto-grow
                      clearable
                      color="primary"
                      label="Añade información relevante a la atención en el local"
                      outlined
                      rows="4">
          </v-textarea>
        </div>

        <!-- Delivery -->
        <!--<v-divider class="mt-2"></v-divider>-->
        <v-list-item class="px-0">
          <v-list-item-title>
            <p class="font-weight-medium mb-1">Servicio al domicilio
              <span class="text-subtitle-2"
                    style="color: gray">{{ selectedBusiness.home_delivery ? '(Activado)' : '(Desactivado)' }}</span></p>
          </v-list-item-title>
          <v-switch v-model="selectedBusiness.home_delivery" color="primary" inset>
          </v-switch>
        </v-list-item>

        <div v-if="selectedBusiness.home_delivery">
          <v-card class="mb-10 pa-5" outlined>
            <p class="pb-2">
              Añade las áreas donde prestar el servicio
              <small>(Ej. barrio, tramo, municipio, provincia)</small>
            </p>

            <validation-observer ref="deliveryZoneObserver" v-slot="{invalid}">
              <v-row>
                <v-col cols="12" md="5" sm="6">
                  <validation-provider v-slot="{errors}" :rules="{required:selectedBusiness.home_delivery}">
                    <v-text-field v-model="deliveryZone.zone"
                                  :error-messages="errors"
                                  clearable
                                  color="primary"
                                  hide-details
                                  label="Nombre del área"
                                  maxlength="40"
                                  outlined>
                    </v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="6" md="2" sm="3">
                  <validation-provider v-slot="{errors}" :rules="{required:selectedBusiness.home_delivery}">
                    <v-text-field v-model.number="deliveryZone.value"
                                  :error-messages="errors"
                                  color="primary"
                                  hide-details
                                  label="Precio... No te pases ;)"
                                  outlined
                                  type="number">
                    </v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="6" md="2" sm="3">
                  <validation-provider v-slot="{errors}" :rules="{required:selectedBusiness.home_delivery}">
                    <v-select v-model="deliveryZone.currency"
                              :error-messages="errors"
                              :items="currencyTypes"
                              color="primary"
                              hide-details
                              label="Moneda"
                              outlined>
                    </v-select>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="3" sm="12">
                  <v-btn block
                         class="text-none"
                         color="#bdbdbd"
                         depressed
                         outlined
                         style="background-color: #f5f5f7"
                         x-large
                         @click="addDeliveryZone()">
                    <span style="color: #008081; font-size: 15px">Agregar área</span>
                  </v-btn>
                </v-col>
              </v-row>
            </validation-observer>

            <div class="pt-5">
              <v-chip-group id="delivery_zones" color="#008081"
                            column>
                <v-chip v-for="(delZone, index) in selectedBusiness.home_delivery_zones"
                        class="mr-2 my-chip-delete"
                        :key="index"
                        close
                        close-icon="mdi-delete"
                        color="greyPrimary"
                        label
                        @click:close="removeDeliveryZone(index)">
                    <span class="font-weight-bold pr-1">
                      {{ delZone.zone }}
                    </span>
                  {{ delZone.value + " " + delZone.currency }}
                </v-chip>
              </v-chip-group>
            </div>
          </v-card>
          <v-textarea v-model="selectedBusiness.home_delivery_note"
                      auto-grow
                      clearable
                      color="primary"
                      label="Añade información relevante al servicio"
                      outlined
                      rows="4">
          </v-textarea>
        </div>

        <v-divider class="mt-2"></v-divider>
        <h3 class="font-weight-medium my-8">Ajustes avanzados</h3>
        <p class="font-weight-medium mb-4">¿Cómo vizualizar los productos?</p>
        <v-item-group>
          <v-row>
            <v-col cols="12" sm="6">
              <v-item v-slot="{ active, toggle }">
                <v-sheet outlined color="greyPrimary" class="pa-3" style="cursor: pointer"
                         @click="selectedBusiness.view_mode = 'list'">
                  <div class="d-flex justify-space-between">
                    <p class="pl-1">En forma de lista</p>
                    <v-btn icon
                           dark>
                      <v-icon color="primary">
                        {{ selectedBusiness.view_mode === 'list' ? 'mdi-heart' : 'mdi-heart-outline' }}
                      </v-icon>
                    </v-btn>
                  </div>

                  <v-card class="mb-3 pa-0" outlined>
                    <v-skeleton-loader boilerplate type="list-item-avatar-two-line"></v-skeleton-loader>
                  </v-card>
                  <v-card class="pa-0" outlined>
                    <v-skeleton-loader boilerplate type="list-item-avatar-two-line"></v-skeleton-loader>
                  </v-card>
                </v-sheet>
              </v-item>
            </v-col>
            <v-col cols="12" sm="6">
              <v-item v-slot="{ active, toggle }">
                <v-sheet outlined color="greyPrimary" class="pa-3" style="cursor: pointer"
                         @click="selectedBusiness.view_mode = 'card'">
                  <div class="d-flex justify-space-between">
                    <p class="pl-1">En forma de tarjeta</p>
                    <v-btn icon
                           dark>
                      <v-icon color="primary">
                        {{ selectedBusiness.view_mode === 'card' ? 'mdi-heart' : 'mdi-heart-outline' }}
                      </v-icon>
                    </v-btn>
                  </div>
                  <v-row>
                    <v-col cols="6" class="pb-0">
                      <v-card class="mb-3 pa-0" outlined>
                        <v-skeleton-loader boilerplate type="image, list-item"></v-skeleton-loader>
                      </v-card>
                    </v-col>
                    <v-col cols="6" class="pb-0">
                      <v-card class="pa-0" outlined>
                        <v-skeleton-loader boilerplate type="image, list-item"></v-skeleton-loader>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-sheet>
              </v-item>
            </v-col>
          </v-row>
        </v-item-group>

        <v-list-item class="px-0 mt-3">
          <v-list-item-title>
            <p class="font-weight-medium mb-1">Mostrar opción de pedidos en línea
              <span class="text-subtitle-2"
                    style="color: gray">{{ selectedBusiness.allow_orders ? '(Activado)' : '(Desactivado)' }}</span></p>
          </v-list-item-title>
          <v-switch v-model="selectedBusiness.allow_orders" color="primary" inset>
          </v-switch>
        </v-list-item>

        <v-divider class="mt-2"></v-divider>

        <!-- Buttons Update -->
        <v-btn :loading="loading"
               block
               class="mt-10 text-none"
               color="primary"
               large
               rounded
               :disabled="!dataChanged"
               type="submit">
          Actualizar
        </v-btn>
      </v-form>

    </v-container>
  </div>
</template>

<script>

import {min, regex, required} from 'vee-validate/dist/rules'
import {extend, setInteractionMode, ValidationObserver, ValidationProvider} from 'vee-validate'
import services from "@/services";
import {isValidPhoneNumber} from 'libphonenumber-js'
import {business_categories, cuba_states, getFirstChar} from '@/utils/data'


const LoadingGeneral = () => import(/* webpackPrefetch: true */ "@/components/LoadingGeneral");

setInteractionMode('eager');

extend('regex', {
  ...regex,
  message: 'El campo no es válido.',
})
extend('required', {
  ...required,
  message: 'El campo no puede estar vacio.',
})
extend('min', {
  ...min,
  message: 'El campo debe contener más de {length} caracteres.',
})
extend("businessName", {
  message: "El nombre del negocio no es válido o no puede ser usado.",
  validate: value => {
    if (value === '')
      return false;

    return /^[a-z\d\-_\s]+$/i.test('value') !== false;
  }
});
extend('isValidPhoneNumber', {
  ...required,
  message: 'El número de teléfono no es válido.',
  validate: function (val) {
    return isValidPhoneNumber(val);
  }
})

export default {
  name: "EditBusiness",
  components: {
    LoadingGeneral,
    ValidationProvider,
    ValidationObserver
  },
  data: () => ({
    switch1: false,
    switch2: false,
    marker: true,
    dialog: false,
    dialogShare: false,
    currencyTypes: ["CUP"],
    selectedPhoneNumber: {
      country: 'CU',
      phone: ''
    },
    selectedBusiness: {
      id: "",
      name: "",
      slug: "",
      rating: 0,

      address: "",
      phone_number: "",
      state: "",
      city: "",
      description: "",
      category: "",

      qr_code: '',

      view_mode: 'list',
      allow_orders: true,

      home_delivery: false,
      home_delivery_zones: [],
      home_delivery_note: "",

      delivery_in_business: true,
      delivery_in_business_note: "",

      logo: {
        id: "",
        path: "",
        thumb: ""
      },
      cover: {
        id: "",
        path: "",
        thumb: ""
      }
    },
    businessLogo: {
      file: undefined,
      url: ''
    },
    businessCover: {
      file: undefined,
      url: ''
    },
    baseUrl: window.location.origin,
    businessName: '',
    loading: false,
    pageLoaded: false,
    dataMounted: false,
    availableStates: cuba_states,
    availableBusinessCategories: business_categories,
    deliveryZone: {
      zone: "",
      value: 0,
      currency: "CUP"
    },
    dataChanged: false
  }),
  async created() {
    const slug = this.$route.params.slug;
    if (slug) {
      try {
        const result = await services.business.getBusinessInfo(slug);
        if (result && result.data && result.data.id) {
          this.selectedBusiness = result.data;

          this.businessName = this.selectedBusiness.name;

          //setting logo
          if (this.selectedBusiness.logo && this.selectedBusiness.logo.thumb && this.selectedBusiness.logo.thumb !== '') {
            this.businessLogo = {
              file: undefined,
              url: this.selectedBusiness.logo.thumb
            }
          }
          // setting cover
          if (this.selectedBusiness.cover && this.selectedBusiness.cover.path && this.selectedBusiness.cover.path !== '') {
            this.businessCover = {
              file: undefined,
              url: this.selectedBusiness.cover.path
            }
          }

          this.pageLoaded = true;
          setTimeout(() => {
            this.dataMounted = true
          }, 2000)
        }
      } catch (e) {
        console.log(e);
        await this.$router.push({name: 'error'});
      }
    } else {
      await this.$router.push({name: 'error'});
    }
  },
  methods: {
    removeDeliveryZone(index) {
      this.selectedBusiness.home_delivery_zones.splice(index, 1);
    },
    addDeliveryZone: async function () {
      const validForm = await this.$refs.deliveryZoneObserver.validate();
      if (!validForm) {
        return;
      }

      this.selectedBusiness.home_delivery_zones.push({
        zone: this.deliveryZone.zone,
        value: parseInt(this.deliveryZone.value),
        currency: this.deliveryZone.currency
      });

      // clear info
      this.deliveryZone = {
        zone: "",
        value: 0,
        currency: "CUP"
      };
      this.$refs.deliveryZoneObserver.reset();
    },
    updateBusiness: async function () {
      const validForm = await this.$refs.observer.validate();
      if (!validForm)
        return;

      if (this.businessName.toLowerCase() !== this.selectedBusiness.name.toLocaleLowerCase()) {
        const res = await this.$dialog.warning({
          text: 'El nombre del negocio va a cambiar y esto causará que la URL compartida anteriormente deje de funcionar. <br/><br/>¿ Está seguro que desea realizar el cambio ?',
          title: 'SeOferta',
          actions: {
            false: 'Cancelar',
            true: {
              text: 'Aceptar',
              color: 'primary'
            }
          },
          persistent: true,
          color: '#d3691f',
          icon: false
        });
        if (res !== true)
          return;
      }

      this.loading = true;

      // need to update the cover image
      if (this.businessCover.file) {
        try {
          const uploadResult = await services.media.uploadBusinessCoverImage(this.selectedBusiness.id, this.businessCover.file);
          if (uploadResult && uploadResult.data.id) {
            this.businessCover.file = undefined;
          } else {
            this.$dialog.notify.error('No se ha podido actualizar el cover del negocio.', {
              position: 'bottom-right',
              timeout: 5000,
            })
          }
        } catch (error) {
          console.log(error);
          this.$dialog.notify.error(error.response.data, {
            position: 'bottom-right',
            timeout: 5000,
          })
        }
      }

      // need to update the logo image
      if (this.businessLogo.file) {
        try {
          const uploadResult = await services.media.uploadBusinessImage(this.selectedBusiness.id, this.businessLogo.file);
          if (uploadResult && uploadResult.data.id) {
            this.businessLogo.file = undefined;
          } else {
            this.$dialog.notify.error('No se ha podido actualizar el logo del negocio.', {
              position: 'bottom-right',
              timeout: 5000,
            })
          }
        } catch (error) {
          console.log(error);
          this.$dialog.notify.error(error.response.data, {
            position: 'bottom-right',
            timeout: 5000,
          })
        }
      }

      // update the business info
      try {
        this.selectedBusiness.phone_number = this.cleanPhoneNumber(this.selectedBusiness.phone_number);
        const updateResult = await services.business.updateBusiness(this.selectedBusiness);
        if (updateResult.data.id) {
          await this.$store.dispatch('auth/updateBusiness', updateResult.data);
          this.$dialog.notify.success('La información se ha actualizado correctamente.', {
            position: 'bottom-right',
            timeout: 5000,
          })
          const newSlug = updateResult.data.slug.toLowerCase();
          if (newSlug !== this.selectedBusiness.slug.toLowerCase()) {

            this.businessName = updateResult.data.name;
            this.selectedBusiness = updateResult.data;

            setTimeout(async () => {
              await this.$router.push({name: 'admin_business_edit_business', params: {slug: newSlug}});
            }, 3000);
          }
        }
      } catch (error) {
        console.log(error);
        this.$dialog.notify.error(error.response.data, {
          position: 'bottom-right',
          timeout: 5000,
        })
      } finally {
        this.loading = false;
      }
    },
    onFileChange(e) {
      if (e.target.files.length === 0)
        return;

      const file = e.target.files[0];

      // check file size
      const filesize = ((file.size / 1024) / 1024).toFixed(4);
      if (filesize > 8.0) {
        this.$dialog.notify.warning('La foto seleccionada es demasiado grande.', {
          position: 'bottom-right',
          timeout: 5000,
        })
        return;
      }

      const extension = file.name.split('.').pop();
      if (extension.toUpperCase() === 'JFIF') {
        this.$dialog.notify.warning('La foto seleccionada no tiene el formato correcto.', {
          position: 'bottom-right',
          timeout: 5000,
        })
        return;
      }

      switch (e.target.name) {
        case 'fileLogo': {
          this.businessLogo.file = file;
          this.businessLogo.url = URL.createObjectURL(file);
          break;
        }
        case 'fileCover': {
          this.businessCover.file = file;
          this.businessCover.url = URL.createObjectURL(file);
          break;
        }
      }
    },
    copyToClipboard: function () {
      const el = document.getElementById('url-business-field');
      el.focus();
      el.select();
      document.execCommand('copy');
      el.blur()
      //navigator.clipboard.writeText(this.getBusinessUrl(this.selectedBusiness.slug));
      this.$dialog.notify.success('La URL se ha copiado correctamente.', {
        position: 'bottom-right',
        timeout: 2000,
      })
    },
    cleanPhoneNumber: function (phoneNumber) {
      phoneNumber = this.replaceAll(phoneNumber, ' ', '');
      phoneNumber = this.replaceAll(phoneNumber, '(', '');
      phoneNumber = this.replaceAll(phoneNumber, ')', '');
      phoneNumber = this.replaceAll(phoneNumber, '-', '');
      phoneNumber = phoneNumber.trim();
      return phoneNumber;
    },
    replaceAll: function (string, search, replace) {
      return string.split(search).join(replace)
    },
    getFirstCharacter: getFirstChar,
    getBusinessUrl: function (slug) {
      return window.location.origin + "/" + slug;
    }
  },
  computed: {
    availableCities: function () {
      const state = this.availableStates.find(state => state.name === this.selectedBusiness.state)
      if (state)
        return state.cities;
      return [];
    }
  },
  watch: {
    selectedBusiness: {
      handler() {
        if (this.dataMounted === true) {
          this.dataChanged = true
        }
      },
      deep: true
    }
  },
};
</script>

<style>

.v-skeleton-loader__image {
  height: 110px!important;
}

.my-chip-delete button {
  color: #DD5A54 !important;
}

.v-icon.icon-hover {
  color: #00999a;
}

.v-icon {
  color: #9e9e9e;
}

.v-btn--outlined.button-copy {
  border: thin solid currentColor;
  border-left: none;
}

.v-btn:not(.v-btn--round).v-size--x-large {
  height: 56px;
}

</style>

