<template>
  <div>
    <v-container class="pa-5 mb-5">
      <h2>
        <v-btn class="mr-2"
               depressed
               fab
               icon
               small
               @click="$router.go(-1)">
          <v-icon color="primary">
            mdi-arrow-left
          </v-icon>
        </v-btn>
        Mi suscripción
      </h2>
      <p class="text-subtitle-1">
        Desde aquí podrás administrar o cambiar tu suscripción
      </p>
      <v-alert
          class="pa-5 mt-5"
          color="greyPrimary"
          dense
          prominent
      >
        <div class="d-flex align-center">
          <v-avatar class="mr-5">
            <img :src="require('@/assets/diamond-secondary.svg')">
          </v-avatar>
          <p class="mb-0">
            <span class="font-weight-medium">Enhorabuena, tienes 30 días gratis con todos los beneficios del Plan Profesional ¡Disfrutalo!</span>
            <br>Al terminar el plazo, automáticamente serás inscrito en el Plan Básico-Gratis.
            Si deseas mantener los beneficios actuales debes renovar el Plan Profesional antes de finalizar los 30 días
            gratis.
          </p>
        </div>
      </v-alert>

      <v-row class="mt-5">
        <v-col cols="12" md="4">
          <v-card outlined class="pa-5 rounded-xl">
            <h3 class="mb-5 font-weight-medium">SeOferta Profesional</h3>
            <div class="align-center d-flex">
              <div>
                <v-progress-circular
                    :rotate="-90"
                    :size="90"
                    :width="10"
                    :value="value"
                    color="secondary"
                >
                  <div class="text-center">
                    <p style="height: 17px;" class="mb-0 font-weight-medium">{{ value }}</p>
                    <small>Días</small>
                  </div>
                </v-progress-circular>
              </div>
              <div class="ml-5">
                <p class="font-weight-medium mb-2">Factura actual:
                  <v-chip
                      class="ma-1" small
                  >
                    $ 100 CUP
                  </v-chip>
                </p>
                <p class="mb-0 text-body-2">Quedan 15 días del ciclo de facturación (7 de nov. de 2021) </p>
              </div>
            </div>
            <v-btn class="mt-8 text-none" color="secondary" block rounded>
              Renovar plan
            </v-btn>
          </v-card>
          <v-card outlined class="pa-5 rounded-xl  mt-5">
            <h3 class="mb-5 font-weight-medium">SeOferta Básico</h3>
            <div class="align-center d-flex">
              <div>
                <v-progress-circular
                    :rotate="-90"
                    :size="90"
                    :width="10"
                    :value="100"
                    color="primary"
                >
                  <div class="text-center">
                    <v-icon color="primary">mdi-infinity</v-icon>
                  </div>
                </v-progress-circular>
              </div>
              <div class="ml-5">
                <p class="font-weight-medium mb-2">Factura actual:
                  <v-chip
                      class="ma-1" small
                  >
                    $ 0 CUP
                  </v-chip>
                </p>
                <p class="mb-0 text-body-2">Nuestra versión básica es buena, pero puedes crecer aún más.</p>
              </div>
            </div>
            <v-btn class="mt-8 text-none" color="primary" block rounded>
              Mejorar plan
            </v-btn>
          </v-card>
        </v-col>
        <v-col cols="12" md="8">
          <div class="pa-5">
            <h3 class="font-weight-medium">Detalles de la suscricpción</h3>
            <p>Al estar inscrito en el Plan Profesional podrás disfrutar de los siguientes beneficios: </p>
            <div class="d-flex flex-wrap">
              <v-list dense class="pr-5 pa-0">
                <v-list-item class="px-0">
                  <v-list-item-icon class="mr-3">
                    <v-icon color="secondary">mdi-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-titles class="text-left">Administrar un negocio</v-list-item-titles>
                </v-list-item>
                <v-list-item class="px-0">
                  <v-list-item-icon class="mr-3">
                    <v-icon color="secondary">mdi-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-titles class="text-left">Productos ilimitados</v-list-item-titles>
                </v-list-item>
                <v-list-item class="px-0">
                  <v-list-item-icon class="mr-3">
                    <v-icon color="secondary">mdi-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-titles class="text-left">Soporte 24/7</v-list-item-titles>
                </v-list-item>
              </v-list>
              <v-list dense class="pa-0">
                <v-list-item class="px-0">
                  <v-list-item-icon class="mr-3">
                    <v-icon color="secondary">mdi-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-titles class="text-left pr-2">Sin anuncios de publicidad</v-list-item-titles>
                </v-list-item>
                <v-list-item class="px-0">
                  <v-list-item-icon class="mr-3">
                    <v-icon color="secondary">mdi-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-titles class="text-left">Promoción</v-list-item-titles>
                </v-list-item>
                <v-list-item class="px-0">
                  <v-list-item-icon class="mr-3">
                    <v-icon color="secondary">mdi-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-titles class="text-left">Notificaciones</v-list-item-titles>
                </v-list-item>
              </v-list>
            </div>
          </div>
          <v-divider class="my-5 mx-5"></v-divider>
          <div class="pa-5">
            <h3 class="font-weight-medium">Historial de pago</h3>
            <v-card elevation="0">
              <v-card-title>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    single-line
                    hide-details
                    clearable
                ></v-text-field>
              </v-card-title>
              <v-data-table
                  :headers="headers"
                  :items="desserts"
                  :items-per-page="5"
                  :search="search"
              ></v-data-table>
            </v-card>
          </div>
          <v-divider class="my-5 mx-5"></v-divider>
          <div class="pa-5">
            <h3 class="font-weight-medium mb-8">Mejora tu plan</h3>
            <v-row>
              <v-col cols="12" sm="6">
                <v-card class="rounded-xl px-4 py-3">
                  <div class="px-3 mt-3">
                    <h4>Profesional</h4>
                    <p><span class="font-weight-medium">$ 100</span> CUP/ mes</p>
                  </div>
                  <v-card-actions>
                    <v-btn
                        class="text-none" rounded depressed
                        @click="show1 = !show1"
                    >
                      Detalles
                      <v-icon>{{ show1 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn rounded depressed color="secondary"
                           class="text-none"
                    >Comprar plan
                    </v-btn>
                  </v-card-actions>
                  <v-expand-transition>
                    <div v-show="show1">
                      <v-divider></v-divider>
                      <v-card-text>
                        <v-list dense>
                          <v-list-item class="px-0">
                            <v-list-item-icon class="mr-3">
                              <v-icon color="secondary">mdi-check</v-icon>
                            </v-list-item-icon>
                            <v-list-item-titles class="text-left">Administrar un negocio</v-list-item-titles>
                          </v-list-item>
                          <v-list-item class="px-0">
                            <v-list-item-icon class="mr-3">
                              <v-icon color="secondary">mdi-check</v-icon>
                            </v-list-item-icon>
                            <v-list-item-titles class="text-left">Productos ilimitados</v-list-item-titles>
                          </v-list-item>
                          <v-list-item class="px-0">
                            <v-list-item-icon class="mr-3">
                              <v-icon color="secondary">mdi-check</v-icon>
                            </v-list-item-icon>
                            <v-list-item-titles class="text-left">Soporte 24/7</v-list-item-titles>
                          </v-list-item>
                          <v-list-item class="px-0">
                            <v-list-item-icon class="mr-3">
                              <v-icon color="secondary">mdi-check</v-icon>
                            </v-list-item-icon>
                            <v-list-item-titles class="text-left pr-2">Sin anuncios de publicidad</v-list-item-titles>
                          </v-list-item>
                          <v-list-item class="px-0">
                            <v-list-item-icon class="mr-3">
                              <v-icon color="secondary">mdi-check</v-icon>
                            </v-list-item-icon>
                            <v-list-item-titles class="text-left">Promoción</v-list-item-titles>
                          </v-list-item>
                          <v-list-item class="px-0">
                            <v-list-item-icon class="mr-3">
                              <v-icon color="secondary">mdi-check</v-icon>
                            </v-list-item-icon>
                            <v-list-item-titles class="text-left">Notificaciones</v-list-item-titles>
                          </v-list-item>
                        </v-list>
                      </v-card-text>
                    </div>
                  </v-expand-transition>
                </v-card>
              </v-col>

              <v-col cols="12" sm="6">
                <v-card class="rounded-xl px-4 py-3">
                  <div class="px-3 mt-3">
                    <div class="d-flex justify-space-between">
                      <div>
                        <h4>Profesional</h4>
                        <p><span class="font-weight-medium">$ 1 000</span> CUP/ año</p>
                      </div>
                      <p class="ml-3 font-weight-medium"
                            style="color: #008081">¡Ahorra $200 CUP!</p>
                    </div>
                  </div>
                  <v-card-actions>
                    <v-btn
                        class="text-none" rounded depressed
                        @click="show2 = !show2"
                    >
                      Detalles
                      <v-icon>{{ show2 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn rounded depressed color="secondary"
                           class="text-none"
                    >Comprar plan
                    </v-btn>
                  </v-card-actions>
                  <v-expand-transition>
                    <div v-show="show2">
                      <v-divider></v-divider>
                      <v-card-text>
                        <v-list dense>
                          <v-list-item class="px-0">
                            <v-list-item-icon class="mr-3">
                              <v-icon color="secondary">mdi-check</v-icon>
                            </v-list-item-icon>
                            <v-list-item-titles class="text-left">Administrar un negocio</v-list-item-titles>
                          </v-list-item>
                          <v-list-item class="px-0">
                            <v-list-item-icon class="mr-3">
                              <v-icon color="secondary">mdi-check</v-icon>
                            </v-list-item-icon>
                            <v-list-item-titles class="text-left">Productos ilimitados</v-list-item-titles>
                          </v-list-item>
                          <v-list-item class="px-0">
                            <v-list-item-icon class="mr-3">
                              <v-icon color="secondary">mdi-check</v-icon>
                            </v-list-item-icon>
                            <v-list-item-titles class="text-left">Soporte 24/7</v-list-item-titles>
                          </v-list-item>
                          <v-list-item class="px-0">
                            <v-list-item-icon class="mr-3">
                              <v-icon color="secondary">mdi-check</v-icon>
                            </v-list-item-icon>
                            <v-list-item-titles class="text-left pr-2">Sin anuncios de publicidad</v-list-item-titles>
                          </v-list-item>
                          <v-list-item class="px-0">
                            <v-list-item-icon class="mr-3">
                              <v-icon color="secondary">mdi-check</v-icon>
                            </v-list-item-icon>
                            <v-list-item-titles class="text-left">Promoción</v-list-item-titles>
                          </v-list-item>
                          <v-list-item class="px-0">
                            <v-list-item-icon class="mr-3">
                              <v-icon color="secondary">mdi-check</v-icon>
                            </v-list-item-icon>
                            <v-list-item-titles class="text-left">Notificaciones</v-list-item-titles>
                          </v-list-item>
                        </v-list>
                      </v-card-text>
                    </div>
                  </v-expand-transition>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>

    </v-container>
  </div>
</template>

<script>
export default {
  name: "Subscription",
  data() {
    return {
      search: '',
      interval: {},
      show1: false,
      show2: false,
      value: 0,
      headers: [
        {
          text: 'Suscripción', value: 'name', align: 'start'
        },
        {
          text: 'Fecha de pago', value: 'fecha'
        },
        {
          text: 'Días activo', value: 'dias'
        },
        {
          text: 'Costo', value: 'costo'
        }
      ],
      desserts: [
        {
          name: 'Profesional',
          fecha: '3 de octubre de 2021',
          dias: '30',
          costo: '1000'
        },
        {
          name: 'Profesional',
          fecha: '18 de octubre de 2021',
          dias: '15',
          costo: '100'
        },
        {
          name: 'Básico',
          fecha: '15 de noviembre de 2021',
          dias: '30',
          costo: '0'
        },
      ]
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  mounted() {
    this.interval = setInterval(() => {
      if (this.value === 30) {
        return (this.value = 0)
      }
      this.value += 1
    }, 1000)
  },
}
</script>

<style scoped>

</style>
