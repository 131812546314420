var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-overlay',{attrs:{"value":!_vm.pageLoaded}},[_c('loading-general')],1),(!_vm.categoryDialog)?_c('v-container',{directives:[{name:"show",rawName:"v-show",value:(_vm.pageLoaded === true),expression:"pageLoaded === true"}],staticClass:"pa-5 mb-5"},[_c('h2',[_c('v-btn',{staticClass:"mr-2",attrs:{"to":{ name: 'admin_business_list_products' },"depressed":"","fab":"","icon":"","small":""}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-arrow-left ")])],1),_vm._v(" "+_vm._s(_vm.creatingProduct === true ? 'Crear producto' : 'Editar producto')+" ")],1),_c('p',{staticClass:"pt-3 text-subtitle-1"},[_vm._v("Añade la información necesaria para "+_vm._s(_vm.creatingProduct === true ? 'crear' : 'actualizar')+" el producto ")]),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{staticClass:"pt-3",on:{"submit":function($event){$event.preventDefault();return _vm.submitProduct.apply(null, arguments)}}},[_c('h3',{staticClass:"font-weight-medium mb-5"},[_vm._v("Información principal")]),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":{required: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"clearable":"","color":"#00999a","label":"Nombre del producto","outlined":""},model:{value:(_vm.selectedProduct.name),callback:function ($$v) {_vm.$set(_vm.selectedProduct, "name", $$v)},expression:"selectedProduct.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('validation-provider',{attrs:{"rules":{required: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"color":"#00999a","label":"Precio ...No te pases ;)","outlined":"","type":"number"},model:{value:(_vm.selectedProduct.price),callback:function ($$v) {_vm.$set(_vm.selectedProduct, "price", $$v)},expression:"selectedProduct.price"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('validation-provider',{attrs:{"rules":{required: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.currencyTypes,"color":"#00999a","label":"Moneda","outlined":""},model:{value:(_vm.selectedProduct.currency),callback:function ($$v) {_vm.$set(_vm.selectedProduct, "currency", $$v)},expression:"selectedProduct.currency"}})]}}],null,true)})],1)],1),_c('v-textarea',{attrs:{"auto-grow":"","clearable":"","color":"primary","label":"Cuenta un poco más sobre tu producto ...","outlined":"","rows":"4"},model:{value:(_vm.selectedProduct.description),callback:function ($$v) {_vm.$set(_vm.selectedProduct, "description", $$v)},expression:"selectedProduct.description"}}),_c('v-divider',{staticClass:"mt-4"}),_c('h3',{staticClass:"font-weight-medium mt-8 mb-5"},[_vm._v("Categoría")]),_c('v-row',{staticClass:"pt-3"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"8","sm":"7"}},[_c('validation-provider',{attrs:{"rules":{required: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"item-text":'name',"item-value":'id',"items":_vm.businessCategories,"clearable":"","label":"Seleccionar categoría","no-data-text":"Aún no has creado ninguna categoría","outlined":""},model:{value:(_vm.selectedProduct.category),callback:function ($$v) {_vm.$set(_vm.selectedProduct, "category", $$v)},expression:"selectedProduct.category"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"4","sm":"5"}},[_c('v-btn',{staticClass:"text-none mb-5",staticStyle:{"background-color":"#f5f5f7","font-size":"15px"},attrs:{"block":"","color":"#bdbdbd","depressed":"","outlined":"","x-large":""},on:{"click":function($event){return _vm.toggleCategoryDialog()}}},[_c('span',{staticStyle:{"color":"#008081"}},[_vm._v("Crear nueva categoría")])])],1)],1),_c('v-divider',{staticClass:"mt-4"}),_c('v-list-item',{staticClass:"px-0"},[_c('v-list-item-title',[_c('p',{staticClass:"font-weight-medium mb-1"},[_vm._v("Mostrar producto "),_c('span',{staticClass:"text-subtitle-2",staticStyle:{"color":"gray"}},[_vm._v(_vm._s(_vm.selectedProduct.is_active ? '(Activado)' : '(Desactivado)'))])])]),_c('v-switch',{attrs:{"color":"#00999a","inset":""},model:{value:(_vm.selectedProduct.is_active),callback:function ($$v) {_vm.$set(_vm.selectedProduct, "is_active", $$v)},expression:"selectedProduct.is_active"}})],1),_c('v-divider',{staticClass:"mb-3"}),_c('h3',{staticClass:"font-weight-medium mt-8 mb-5"},[_vm._v("Fotos")]),_c('div',{staticClass:"d-flex justify-start flex-wrap mb-16"},[_c('v-item',{staticClass:"ma-1"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"text-center",class:{ 'on-hover': hover },attrs:{"outlined":"","width":"150","height":"100"},nativeOn:{"click":function($event){return _vm.$refs.file1.click()}}},[(_vm.productsImages.image1.url === '')?_c('v-icon',{staticClass:"mt-7",class:{ 'icon-hover': hover },attrs:{"large":""}},[_vm._v("mdi-camera ")]):_vm._e(),(_vm.productsImages.image1.url !== '')?_c('v-img',{attrs:{"alt":_vm.selectedProduct.name,"lazy-src":require('../../../assets/loading.svg'),"src":_vm.productsImages.image1.url,"aspect-ratio":"1.5","contain":"","height":"96"}}):_vm._e(),_c('input',{ref:"file1",attrs:{"accept":"image/*","hidden":"","name":"file1","type":"file"},on:{"change":_vm.onFileChange}})],1)]}}],null,true)})],1),_c('v-item',{staticClass:"ma-1"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"text-center",class:{ 'on-hover': hover },attrs:{"width":"150","height":"100","outlined":""},nativeOn:{"click":function($event){return _vm.$refs.file2.click()}}},[(_vm.productsImages.image2.url === '')?_c('v-icon',{staticClass:"mt-7",class:{ 'icon-hover': hover },attrs:{"large":""}},[_vm._v("mdi-camera ")]):_vm._e(),(_vm.productsImages.image2.url !== '')?_c('v-img',{attrs:{"alt":_vm.selectedProduct.name,"lazy-src":require('../../../assets/loading.svg'),"src":_vm.productsImages.image2.url,"aspect-ratio":"1.5","contain":"","height":"96"}}):_vm._e(),_c('input',{ref:"file2",attrs:{"accept":"image/*","hidden":"","name":"file2","type":"file"},on:{"change":_vm.onFileChange}})],1)]}}],null,true)})],1),_c('v-item',{staticClass:"ma-1"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"text-center",class:{ 'on-hover': hover },attrs:{"width":"150","height":"100","outlined":""},nativeOn:{"click":function($event){return _vm.$refs.file3.click()}}},[(_vm.productsImages.image3.url === '')?_c('v-icon',{staticClass:"mt-7",class:{ 'icon-hover': hover },attrs:{"large":""}},[_vm._v("mdi-camera ")]):_vm._e(),(_vm.productsImages.image3.url !== '')?_c('v-img',{attrs:{"alt":_vm.selectedProduct.name,"lazy-src":require('../../../assets/loading.svg'),"src":_vm.productsImages.image3.url,"aspect-ratio":"1.5","contain":"","height":"96"}}):_vm._e(),_c('input',{ref:"file3",attrs:{"accept":"image/*","hidden":"","name":"file3","type":"file"},on:{"change":_vm.onFileChange}})],1)]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":_vm.creatingProduct === true ? 12 : 8,"cols":"12"}},[_c('v-btn',{staticClass:"text-none font-weight-medium-",attrs:{"disabled":_vm.deleteLoading || (_vm.creatingProduct === false && _vm.dataChanged === false),"loading":_vm.updateLoading,"block":"","color":"primary","large":"","rounded":"","type":"submit"}},[_vm._v("Guardar ")])],1),(_vm.creatingProduct === false)?_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-btn',{staticClass:"text-none font-weight-medium",attrs:{"disabled":_vm.updateLoading,"loading":_vm.deleteLoading,"block":"","color":"error","large":"","rounded":"","text":""},on:{"click":function($event){return _vm.deleteProduct($event)}}},[_vm._v(" Eliminar producto ")])],1):_vm._e()],1)],1)]}}],null,false,1180139289)})],1):_vm._e(),(_vm.categoryDialog)?_c('CategoryEditor',{attrs:{"categories":_vm.businessCategories},on:{"onBackButton":_vm.toggleCategoryDialog}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }