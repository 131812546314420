<template>
  <div>
    <transition mode="out-in" name="fade">
      <router-view/>
    </transition>
    <menu-app></menu-app>
  </div>
</template>

<script>
const MenuApp = () => import(/* webpackPrefetch: true */ "@/components/MenuApp");

export default {
  name: "AdminBusinessIndex",
  components: {
    MenuApp,
  },
  data: () => ({}),
};
</script>
