<template>
  <div>
    <toolbar></toolbar>
    <transition mode="out-in" name="fade">
      <router-view/>
    </transition>
  </div>
</template>

<script>
const Toolbar = () => import(/* webpackPrefetch: true */ "@/components/Toolbar");

export default {
  name: "AdminIndex",
  data: () => ({}),
  components: {
    Toolbar,
  }
};
</script>

