<template>
  <div>
    <v-overlay :value="!pageLoaded" class="text-center">
      <loading-general></loading-general>
    </v-overlay>
    <v-container class="pa-5 mb-5">
      <!-- Header  -->
      <v-row>
        <v-col cols="12" sm="7">
          <h2>Productos</h2>
          <p class="text-subtitle-1 mb-0">
            Aquí podrás ver y gestionar todos tus productos</p>
        </v-col>
        <v-col cols="12" sm="5">
          <div class="text-left text-sm-right pt-sm-3">
            <v-btn :to="{name: 'admin_business_edit_products' }"
                   class="text-none"
                   color="secondary"
                   dark
                   depressed
                   large
                   rounded>
              <v-icon left>
                mdi-plus
              </v-icon>
              <span>Adicionar producto</span>
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <div v-if="!this.viewProduct">
        <!-- Empty Products  -->
        <v-row>
          <v-col cols="6" sm="4">
            <img :src="require('@/assets/product-animate.svg')" style="max-height: 500px"/>
          </v-col>
          <v-col cols="6" sm="8">
            <v-card class="my-10" flat>
              <v-card-text>
                <p class="text-sm-h6">Aún no has adicionado ningún producto ¿Qué esperas?</p>
              </v-card-text>
              <v-card class="mb-3" outlined>
                <v-skeleton-loader boilerplate
                                   type="list-item-avatar-two-line">
                </v-skeleton-loader>
              </v-card>
              <v-card class="mb-3" outlined>
                <v-skeleton-loader boilerplate
                                   type="list-item-avatar-two-line"></v-skeleton-loader>
              </v-card>
              <v-card outlined>
                <v-skeleton-loader boilerplate
                                   type="list-item-avatar-two-line"></v-skeleton-loader>
              </v-card>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <!-- List Products  -->
        <v-tabs v-model="tab" class="pt-5 pb-5" color="#00999a">
          <v-tab class="text-capitalize">En Venta
            <v-avatar class="mx-2" color="greyPrimary" size="30">{{ activeProducts.length }}</v-avatar>
          </v-tab>
          <v-tab class="text-capitalize">Inactivos
            <v-avatar class="mx-2" color="greyPrimary" size="30">{{ inactiveProducts.length }}</v-avatar>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <!-- active list -->
          <v-tab-item>
            <v-list v-if="activeProducts.length > 0">
              <v-list-item v-for="product in activeProducts"
                           :key="product.id"
                           class="mb-3"
                           style="background: #f6f6f6">
                <v-list-item-avatar color="white" class="mr-5" size="65">
                  <v-img v-if="product.images.length > 0"
                         :lazy-src="require('../../../assets/loading-buffering.svg')"
                         :src="product.images[0].thumb">
                  </v-img>
                  <span v-else
                        class="text-h4 font-weight-medium"
                        style="color: #00999a">
                    {{ getFirstCharacter(product.name) }}
                  </span>
                </v-list-item-avatar>

                <v-list-item-content>
                  <h4 class="font-weight-medium">
                    {{ product.name }}
                  </h4>
                  <v-list-item-subtitle>
                    {{ product.description }}
                  </v-list-item-subtitle>
                  <p class="font-weight-medium text-subtitle-2 mb-0" >$ {{ product.price }} {{ product.currency }}</p>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn
                      :to="{ name: 'admin_business_edit_products', params: {slug: $route.params.slug, productId: product.id} }"
                      depressed fab icon small>
                    <v-icon color="primary">mdi-square-edit-outline</v-icon>
                  </v-btn>
                </v-list-item-action>

              </v-list-item>
            </v-list>
            <v-list v-else>
              <div class="pt-8 text-center">
                <h3 style="color: rgba(0, 0, 0, 0.6)">No hay productos en venta</h3>
                <p style="color: rgba(0, 0, 0, 0.6)">Cuando adiciones o actives un producto, aparecerá aquí</p>
              </div>
            </v-list>
          </v-tab-item>

          <!-- inactive list -->
          <v-tab-item>
            <v-list v-if="inactiveProducts.length > 0">
              <v-list-item  v-for="product in inactiveProducts"
                         :key="product.id"
                         class="mb-3" style="background: #f6f6f6">
              <v-list-item-avatar class="mr-2" size="65">
                <v-img v-if="product.images && product.images.length > 0"
                       :lazy-src="require('../../../assets/loading-buffering.svg')"
                       :src="product.images[0].thumb">
                </v-img>
                <v-icon v-if="!product.images || product.images.length === 0"
                        style="font-size: 60px">mdi-image
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <h4 class="font-weight-medium"
                    style="color: black; font-size: 18px">
                  {{ product.name }}
                </h4>
                <v-list-item-subtitle>
                  {{ product.description }}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="pt-2">
                  <h4 class="font-weight-medium" style="color: black">{{ product.price }} {{ product.currency }}</h4>
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <div class="d-flex">
                  <div>
                    <v-btn depressed fab icon small @click="deleteProduct(product, $event)">
                      <v-icon color="error">mdi-delete</v-icon>
                    </v-btn>
                  </div>
                  <div class="mt-2 pl-2">
                    <v-switch v-model="product.is_active"
                              inset
                              @click="setProductStatus(product, $event)">
                    </v-switch>
                  </div>
                </div>
              </v-list-item-action>
            </v-list-item>
            </v-list>
            <v-list class="text-center" v-else>
              <div class="pt-8 text-center">
                <h3 style="color: rgba(0, 0, 0, 0.6)">No hay productos inactivos</h3>
                <p style="color: rgba(0, 0, 0, 0.6)">Cuando desactives un producto, aparecerá aquí</p>
              </div>
            </v-list>
          </v-tab-item>

        </v-tabs-items>
      </div>

    </v-container>
  </div>
</template>

<script>
import services from "@/services";
import {getFirstChar} from "@/utils/data";

const LoadingGeneral = () => import(/* webpackPrefetch: true */ "@/components/LoadingGeneral");
const MenuApp = () => import(/* webpackPrefetch: true */ "@/components/MenuApp");

export default {
  name: "ListProducts",
  data: () => ({
    viewProduct: true,
    tab: null,
    switch1: true,
    switch2: false,
    businessSlug: '',
    products: [],
    pageLoaded: false
  }),
  components: {
    LoadingGeneral,
    MenuApp,

  },
  async created() {
    const slug = this.$route.params.slug;
    if (slug) {
      this.businessSlug = slug;
      try {
        const result = await services.products.getProducts(slug)
        if (result.data) {
          this.products = result.data;
          if (this.products.length === 0)
            this.viewProduct = false;
        }
      } catch (e) {
        console.log(e);
        await this.$router.push({name: 'errorInternal'});
      } finally {
        this.pageLoaded = true;
      }
    } else {
      await this.$router.push({name: 'error'});
    }
  },
  methods: {
    setProductStatus: async function (product, event) {
      try {
        const result = await services.products.setProductStatus(this.businessSlug, product)
        if (result && result.data) {
          this.$dialog.notify.success('El estado del producto se ha actualizado correctamente.', {
            position: 'bottom-right',
            timeout: 5000,
          })
        }
      } catch (e) {
        console.log(e);
        this.$dialog.notify.error('No se pudo actualizar el estado del producto.', {
          position: 'bottom-right',
          timeout: 5000,
        })
        product.is_active = !product.is_active;
      }
      event.stopPropagation();
    },
    deleteProduct: async function (product, event) {
      const res = await this.$dialog.warning({
        text: '¿ Realmente desea eliminar el producto ?',
        title: 'SeOferta',
        actions: {
          false: 'Cancelar',
          true: {
            text: 'Eliminar',
            color: 'primary'
          }
        },
        persistent: true,
        color: '#00999a',
        icon: false
      });
      if (res !== true)
        return;

      try {
        await services.products.deleteProduct(this.businessSlug, product);
        let index = this.products.findIndex(item => item.id === product.id);
        if (index >= 0) {
          this.products.splice(index, 1);
        }
        this.$dialog.notify.success('El producto fue eliminado correctamente.', {
          position: 'bottom-right',
          timeout: 5000,
        })
      } catch (e) {
        console.log(e);
        this.$dialog.notify.error('No se pudo eliminar el producto.', {
          position: 'bottom-right',
          timeout: 5000,
        })
      } finally {
        event.stopPropagation();
      }
    },
    getFirstCharacter: getFirstChar
  },
  computed: {
    activeProducts: function () {
      return this.products.filter(p => p.is_active === true);
    },
    inactiveProducts: function () {
      return this.products.filter(p => p.is_active === false);
    }
  }
};
</script>

<style scoped>

</style>
