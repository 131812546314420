<template>
  <div>
    <v-overlay :value="!pageLoaded">
      <loading-general></loading-general>
    </v-overlay>
    <v-container class="pa-5 mb-5">
      <h2>
        <v-btn class="mr-2"
               depressed
               fab
               icon
               small
               @click="$router.go(-1)">
          <v-icon color="primary">
            mdi-arrow-left
          </v-icon>
        </v-btn>
        Mi Perfil
      </h2>
      <p class="text-subtitle-1 pb-4">
        Aquí podrás actualizar los datos de tu perfil
      </p>

      <v-tabs vertical>
        <v-tab class="justify-start text-none">
          <v-icon left>
            mdi-account-outline
          </v-icon>
          General
        </v-tab>
        <v-tab class="justify-start text-none">
          <v-icon left>
            mdi-lock-outline
          </v-icon>
          Seguridad
        </v-tab>
        <v-tab-item>
          <v-card class="px-8 py-3">
            <validation-observer ref="observer"
                                 v-slot="{invalid}">
              <v-form @submit.prevent="updateProfile">
                <h3 class="font-weight-medium mb-5">Detalles</h3>

                <!--                <v-card outlined class="mb-8 pa-3 d-flex align-center">-->
                <!--                  <v-avatar size="36" color="primary">-->
                <!--                    <span class="font-weight-medium" style="color: white; font-size: 20px">G</span>-->
                <!--                  </v-avatar>-->
                <!--                  <v-spacer></v-spacer>-->
                <!--                  <v-btn color="#bdbdbd" outlined small depressed rounded-->
                <!--                         style="background-color: #f5f5f7"-->
                <!--                         class="text-none ml-2">-->
                <!--                    <span style="color: #008081">Subir foto</span>-->
                <!--                  </v-btn>-->
                <!--                  <v-btn color="#bdbdbd" outlined small depressed rounded-->
                <!--                         style="background-color: #f5f5f7"-->
                <!--                         class="text-none ml-2">-->
                <!--                    <span style="color: #DD5A54">Eliminar foto</span>-->
                <!--                  </v-btn>-->
                <!--                </v-card>-->

                <validation-provider v-slot="{errors}"
                                     rules="required|min:6">
                  <v-text-field v-model="profileForm.fullName"
                                :error-messages="errors"
                                clearable
                                color="#00999a"
                                label="Nombre y apellidos"
                                outlined>
                  </v-text-field>
                </validation-provider>

                <v-text-field v-model="profileForm.phone"
                              color="#00999a"
                              label="Número de teléfono"
                              outlined
                              readonly>
                </v-text-field>
                <v-divider></v-divider>
                <h3 class="font-weight-medium my-5">Negocios</h3>
                <v-card outlined class="pa-3 d-flex flex-wrap align-center" v-for="bus in userBusiness" :key="bus.id">
                  <v-avatar size="36" color="greyPrimary" class="mr-3">
                    <img
                        v-if="bus.logo && bus.logo.thumb && bus.logo.thumb !== ''"
                        :alt="bus.name"
                        :src="bus.logo.thumb">
                    <span v-else
                          class="font-weight-medium"
                          style="font-size: 20px">{{ firstChar(bus.name) }}
                    </span>
                  </v-avatar>
                  <div>
                    <p class="font-weight-medium mb-0">{{ bus.name }}</p>
                    <a class="text-body-2 text-decoration-underline"
                       link
                       target="_blank"
                       :href="getBusinessUrl(bus.slug)">
                      {{ getBusinessUrl(bus.slug) }}
                      <v-icon color="primary" small>mdi-open-in-new</v-icon>
                    </a>
                  </div>
                  <v-spacer></v-spacer>
                  <v-menu rounded offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon class="text-none"
                             v-bind="attrs"
                             v-on="on">
                        <v-icon>mdi-dots-horizontal</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item :to="{ name: 'admin_business_edit_business', params:{ slug: bus.slug} }"
                                   link>
                        <v-icon left small>mdi-cog</v-icon>
                        <v-list-item-title>Administrar</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-card>
                <v-btn :loading="profileForm.loading"
                       block
                       class="mt-10 text-none"
                       color="primary"
                       large
                       rounded
                       :disabled="dataChangedDetails === false"
                       type="submit">
                  Actualizar
                </v-btn>
              </v-form>
            </validation-observer>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card class="px-8 py-3">
            <validation-observer ref="observer"
                                 v-slot="{invalid}">
              <v-form @submit.prevent="updateProfile">

                <h3 class="font-weight-medium mb-1">Cambiar contraseña</h3>
                <p class="mb-5">Tu seguridad es importante</p>

                <v-text-field v-model="profileForm.oldPassword"
                              :append-icon="!showEye ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                              :type="!showEye ? 'password' : 'text'"
                              color="#00999a"
                              label="Contraseña actual"
                              outlined
                              @click:append="showEye = !showEye">
                </v-text-field>

                <ValidationObserver>
                  <validation-provider v-slot="{errors}"
                                       :rules="{ required: profileForm.oldPassword.length > 0,
                               min: profileForm.oldPassword.length > 0 ? {length: 8}: false  }"
                                       name="password"
                                       vid="password">
                    <v-text-field v-model="profileForm.password"
                                  :append-icon="!showEye ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                                  :error-messages="errors"
                                  :type="!showEye ? 'password' : 'text'"
                                  color="#00999a"
                                  label="Nueva contraseña"
                                  outlined
                                  @click:append="showEye = !showEye">
                    </v-text-field>
                  </validation-provider>

                  <validation-provider v-slot="{errors}"
                                       :rules="{ required: profileForm.oldPassword.length > 0,
                               min: profileForm.oldPassword.length > 0 ? {length: 8}: false,
                               confirm: profileForm.oldPassword.length > 0 ? {target: profileForm.password}: false}">
                    <v-text-field v-model="profileForm.repeatPassword"
                                  :append-icon="!showEye ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                                  :error-messages="errors"
                                  :type="!showEye ? 'password' : 'text'"
                                  color="#00999a"
                                  label="Repetir nueva contraseña"
                                  outlined
                                  @click:append="showEye = !showEye">
                    </v-text-field>
                  </validation-provider>
                </ValidationObserver>

                <v-btn :loading="profileForm.loading"
                       block
                       class="mt-5 text-none"
                       color="primary"
                       large
                       rounded
                       type="submit">
                  Actualizar
                </v-btn>
              </v-form>
            </validation-observer>
          </v-card>
        </v-tab-item>
      </v-tabs>

    </v-container>
  </div>
</template>

<script>
import {min, regex, required} from 'vee-validate/dist/rules'
import {extend, setInteractionMode, ValidationObserver, ValidationProvider} from 'vee-validate'
import services from '@/services'
import {getFirstChar} from '@/utils/data'

const LoadingGeneral = () => import(/* webpackPrefetch: true */ "@/components/LoadingGeneral");

setInteractionMode('eager')

extend('required', {
  ...required,
  message: 'El campo no puede estar vacio.',
})
extend('regex', {
  ...regex,
  message: 'El campo no es válido.',
})
extend('min', {
  ...min,
  message: 'El campo debe contener más de {length} caracteres.',
})
extend('confirm', {
  params: ['target'],
  validate(value, {target}) {
    return value === target;
  },
  message: 'Las contraseñas no coinciden.'
});

export default {
  name: "Profile",
  components: {
    LoadingGeneral,
    ValidationProvider,
    ValidationObserver
  },
  data: () => ({
    date: null,
    showEye: false,
    profileForm: {
      id: '',
      fullName: '',
      phone: '',
      oldPassword: '',
      password: '',
      repeatPassword: '',
      loading: false
    },
    pageLoaded: false,
    dataMounted: false,
    dataChangedDetails: false,
  }),
  mounted() {
    this.profileForm.id = this.loggedUser.id;
    this.profileForm.fullName = this.loggedUser.full_name;
    this.profileForm.phone = this.loggedUser.phone_number;

    this.pageLoaded = true;
    setTimeout(() => {
      this.dataMounted = true
    }, 1000);
  },
  computed: {
    loggedUser() {
      return this.$store.state.auth.user
    },
    userBusiness() {
      return this.$store.state.auth.business;
    },
  },
  methods: {
    /**
     *
     * @returns {Promise<void>}
     */
    updateProfile: async function () {
      const validForm = await this.$refs.observer.validate();
      if (!validForm)
        return;

      try {
        this.profileForm.loading = true;
        const result = await services.users.updateUserInfo(this.profileForm);
        if (result && result.data) {
          await this.$store.dispatch('auth/updateUser', result.data);
          this.$dialog.notify.success('La información se ha actualizado correctamente.', {
            position: 'bottom-right',
            timeout: 5000,
          })
        } else {
          this.$dialog.notify.error('No se ha podido actualizar la información del usuario..', {
            position: 'bottom-right',
            timeout: 5000,
          })
        }
      } catch (error) {
        console.log(error);
        this.$dialog.notify.error(error.response.data, {
          position: 'bottom-right',
          timeout: 5000,
        })
      } finally {
        this.profileForm.loading = false;
      }
    },
    firstChar: getFirstChar,
    getBusinessUrl: function (slug) {
      return window.location.origin + "/" + slug;
    }
  },
  watch: {
    'profileForm.fullName': {
      handler() {
        if (this.dataMounted === true) {
          this.dataChangedDetails = true
        }
      },
    },
  },
};
</script>
