<template>
  <div>
    <v-overlay :value="!pageLoaded">
      <loading-general></loading-general>
    </v-overlay>
    <v-container v-if="!categoryDialog" v-show="pageLoaded === true" class="pa-5 mb-5">
      <!--      region toolbar-->
      <h2>
        <v-btn :to="{ name: 'admin_business_list_products' }"
               class="mr-2"
               depressed
               fab
               icon
               small>
          <v-icon color="primary">
            mdi-arrow-left
          </v-icon>
        </v-btn>
        {{ creatingProduct === true ? 'Crear producto' : 'Editar producto' }}
      </h2>
      <p class="pt-3 text-subtitle-1">Añade la información necesaria para
        {{ creatingProduct === true ? 'crear' : 'actualizar' }} el producto
      </p>
      <!--      endregion-->

      <validation-observer ref="observer"
                           v-slot="{invalid}">
        <v-form class="pt-3" @submit.prevent="submitProduct">
          <!-- region Info-->
          <h3 class="font-weight-medium mb-5">Información principal</h3>
          <v-row>
            <v-col class="pb-0" cols="12" md="6">
              <!-- Name -->
              <validation-provider v-slot="{errors}"
                                   :rules="{required: true}">
                <v-text-field v-model="selectedProduct.name"
                              :error-messages="errors"
                              clearable
                              color="#00999a"
                              label="Nombre del producto" outlined>
                </v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="6" md="3">
              <!-- Price -->
              <validation-provider v-slot="{errors}"
                                   :rules="{required: true}">
                <v-text-field v-model="selectedProduct.price"
                              :error-messages="errors"
                              color="#00999a"
                              label="Precio ...No te pases ;)"
                              outlined
                              type="number">
                </v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="6" md="3">
              <!-- Currency -->
              <validation-provider v-slot="{errors}"
                                   :rules="{required: true}">
                <v-select v-model="selectedProduct.currency"
                          :error-messages="errors"
                          :items="currencyTypes"
                          color="#00999a"
                          label="Moneda"
                          outlined>
                </v-select>
              </validation-provider>
            </v-col>
          </v-row>
          <!-- endregion-->

          <!-- Comment -->
          <v-textarea
              v-model="selectedProduct.description"
              auto-grow
              clearable
              color="primary"
              label="Cuenta un poco más sobre tu producto ..."
              outlined
              rows="4">
          </v-textarea>

          <!-- region Category -->
          <v-divider class="mt-4"></v-divider>
          <h3 class="font-weight-medium mt-8 mb-5">Categoría</h3>
          <v-row class="pt-3">
            <v-col class="py-0" cols="12" md="8" sm="7">
              <validation-provider v-slot="{errors}"
                                   :rules="{required: true}">
                <v-select v-model="selectedProduct.category"
                          :error-messages="errors"
                          :item-text="'name'"
                          :item-value="'id'"
                          :items="businessCategories"
                          clearable
                          label="Seleccionar categoría"
                          no-data-text="Aún no has creado ninguna categoría"
                          outlined>
                </v-select>
              </validation-provider>
            </v-col>
            <v-col class="py-0" cols="12" md="4" sm="5">
              <v-btn block
                     class="text-none mb-5"
                     color="#bdbdbd"
                     depressed
                     outlined
                     style="background-color: #f5f5f7; font-size: 15px"
                     x-large
                     @click="toggleCategoryDialog()">
                <span style="color: #008081">Crear nueva categoría</span>
              </v-btn>
            </v-col>
          </v-row>
          <!--endregion-->

          <!-- State -->
          <v-divider class="mt-4"></v-divider>
          <v-list-item class="px-0">
            <v-list-item-title><p class="font-weight-medium mb-1">Mostrar producto
              <span class="text-subtitle-2"
                    style="color: gray">{{ selectedProduct.is_active ? '(Activado)' : '(Desactivado)' }}</span></p>
            </v-list-item-title>
            <v-switch v-model="selectedProduct.is_active" color="#00999a" inset></v-switch>
          </v-list-item>

          <!-- region Offer -->
          <!--                    <v-divider class="my-3"></v-divider>-->
          <!--                    <v-list-item class="px-0">-->
          <!--                      <v-list-item-title class="font-weight-medium">En oferta-->
          <!--                      </v-list-item-title>-->
          <!--                      <v-switch v-model="switch2" inset></v-switch>-->
          <!--                    </v-list-item>-->
          <!--                    <v-row>-->
          <!--                      <v-col cols="8">-->
          <!--                        <v-text-field color="#00999a"-->
          <!--                                      label="Precio de oferta"-->
          <!--                                      outlined-->
          <!--                                      type="number"></v-text-field>-->
          <!--                      </v-col>-->
          <!--                      <v-col cols="4">-->
          <!--                        <v-select :items="currencyTypes"-->
          <!--                                  color="#00999a"-->
          <!--                                  label="Moneda"-->
          <!--                                  outlined>-->
          <!--                        </v-select>-->
          <!--                      </v-col>-->
          <!--                    </v-row>-->
          <!--endregion-->

          <!-- region Picture -->
          <v-divider class="mb-3"></v-divider>
          <h3 class="font-weight-medium mt-8 mb-5">Fotos</h3>
          <div class="d-flex justify-start flex-wrap mb-16">
            <v-item class="ma-1">
              <v-hover v-slot="{ hover }">
                <v-card :class="{ 'on-hover': hover }"
                        class="text-center"
                        outlined
                        width="150"
                        height="100"
                        @click.native="$refs.file1.click()">
                  <v-icon
                      v-if="productsImages.image1.url === ''"
                      :class="{ 'icon-hover': hover }"
                      class="mt-7" large>mdi-camera
                  </v-icon>
                  <v-img
                      v-if="productsImages.image1.url !== ''"
                      :alt="selectedProduct.name"
                      :lazy-src="require('../../../assets/loading.svg')"
                      :src="productsImages.image1.url"
                      aspect-ratio="1.5"
                      contain
                      height="96"/>
                  <input ref="file1" accept="image/*" hidden name="file1" type="file" @change="onFileChange"/>
                </v-card>
              </v-hover>
            </v-item>
            <v-item class="ma-1">
              <v-hover v-slot="{ hover }">
                <v-card :class="{ 'on-hover': hover }"
                        class="text-center"
                        width="150"
                        height="100"
                        outlined
                        @click.native="$refs.file2.click()">
                  <v-icon
                      v-if="productsImages.image2.url === ''"
                      :class="{ 'icon-hover': hover }"
                      class="mt-7" large>mdi-camera
                  </v-icon>
                  <v-img
                      v-if="productsImages.image2.url !== ''"
                      :alt="selectedProduct.name"
                      :lazy-src="require('../../../assets/loading.svg')"
                      :src="productsImages.image2.url"
                      aspect-ratio="1.5"
                      contain
                      height="96"/>
                  <input ref="file2" accept="image/*" hidden name="file2" type="file" @change="onFileChange"/>
                </v-card>
              </v-hover>
            </v-item>
            <v-item class="ma-1">
              <v-hover v-slot="{ hover }">
                <v-card :class="{ 'on-hover': hover }"
                        class="text-center"
                        width="150"
                        height="100"
                        outlined
                        @click.native="$refs.file3.click()">
                  <v-icon
                      v-if="productsImages.image3.url === ''"
                      :class="{ 'icon-hover': hover }"
                      class="mt-7" large>mdi-camera
                  </v-icon>
                  <v-img
                      v-if="productsImages.image3.url !== ''"
                      :alt="selectedProduct.name"
                      :lazy-src="require('../../../assets/loading.svg')"
                      :src="productsImages.image3.url"
                      aspect-ratio="1.5"
                      contain
                      height="96"/>
                  <input ref="file3" accept="image/*" hidden name="file3" type="file" @change="onFileChange"/>
                </v-card>
              </v-hover>
            </v-item>
          </div>
          <!--          endregion-->

          <!-- Buttons -->
          <v-row>
            <v-col :sm="creatingProduct === true ? 12 : 8" cols="12">
              <v-btn :disabled="deleteLoading || (creatingProduct === false && dataChanged === false)"
                     :loading="updateLoading"
                     block
                     class="text-none font-weight-medium-"
                     color="primary"
                     large
                     rounded
                     type="submit">Guardar
              </v-btn>
            </v-col>
            <v-col v-if="creatingProduct === false" cols="12" sm="4">
              <v-btn :disabled="updateLoading"
                     :loading="deleteLoading"
                     block
                     class="text-none font-weight-medium"
                     color="error"
                     large
                     rounded
                     text
                     @click="deleteProduct($event)">
                Eliminar producto
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </validation-observer>
    </v-container>
    <CategoryEditor v-if="categoryDialog"
                    :categories="businessCategories"
                    @onBackButton="toggleCategoryDialog">
    </CategoryEditor>
  </div>
</template>

<script>

import services from "@/services";
import {extend, setInteractionMode, ValidationObserver, ValidationProvider} from "vee-validate";
import {required} from "vee-validate/dist/rules";

const LoadingGeneral = () => import(/* webpackPrefetch: true */ "@/components/LoadingGeneral");
const MenuApp = () => import(/* webpackPrefetch: true */ "@/components/MenuApp");
const CategoryEditor = () => import(/* webpackPrefetch: true */ "@/components/CategoryEditor");

setInteractionMode('eager')
extend('required', {
  ...required,
  message: 'El campo no puede estar vacio.',
})

export default {
  name: "EditProduct",
  components: {
    LoadingGeneral,
    MenuApp,
    ValidationProvider,
    ValidationObserver,
    CategoryEditor,
  },
  data: () => ({
    currencyTypes: ["CUP", "USD"],
    businessCategories: [],
    selectedProduct: {
      currency: "CUP",
      description: "",
      id: "",
      images: [],
      is_active: true,
      name: "",
      price: 0,
    },
    productsImages: {
      image1: {
        file: undefined,
        url: ''
      },
      image2: {
        file: undefined,
        url: ''
      },
      image3: {
        file: undefined,
        url: ''
      },
    },

    updateLoading: false,
    deleteLoading: false,

    creatingProduct: true,

    pageLoaded: false,
    categoryDialog: false,

    dataChanged: false,
    dataMounted: false,
  }),
  async created() {
    // RESOLVE PRODUCTS CATEGORIES
    try {
      const result = await services.categories.getBusinessCategories(this.$route.params.slug);
      if (result.data) {
        this.businessCategories = result.data.sort((c1, c2) => c1.priority - c2.priority);
      } else {
        await this.$router.push({name: 'error'});
      }
    } catch (error) {
      console.log(error);
      await this.$router.push({name: 'error'});
    }

    // RESOLVE PRODUCTS INFORMATION
    if (this.$route.params.productId) {
      this.creatingProduct = false;

      try {
        const result = await services.products.getProduct(this.$route.params.slug, this.$route.params.productId);
        if (result.data && result.data.id) {

          this.selectedProduct = result.data;
          this.selectedProduct.category = this.selectedProduct.category.id;

          // setting up images
          let image1 = this.selectedProduct.images.find(i => i.priority === 1);
          let image2 = this.selectedProduct.images.find(i => i.priority === 2);
          let image3 = this.selectedProduct.images.find(i => i.priority === 3);
          this.productsImages.image1.url = (image1 && image1.thumb) || '';
          this.productsImages.image2.url = (image2 && image2.thumb) || '';
          this.productsImages.image3.url = (image3 && image3.thumb) || '';

        } else {
          await this.$router.push({name: 'error'});
        }
      } catch (error) {
        console.log(error);
        await this.$router.push({name: 'error'});
      }

    } else {
      this.creatingProduct = true;
    }

    this.pageLoaded = true;
    setTimeout(() => {
      this.dataMounted = true
    }, 2000)
  },
  methods: {
    /**
     *
     */
    submitProduct: async function () {
      const isValid = await this.$refs.observer.validate();
      if (!isValid)
        return;

      this.updateLoading = true;

      // PRODUCT CREATE OR UPDATE
      if (this.creatingProduct) {
        try {
          const creationResult = await services.products.createProduct(this.$route.params.slug, this.selectedProduct);
          if (creationResult && creationResult.data && creationResult.data.id !== '') {
            this.selectedProduct = creationResult.data;
          } else {
            this.$dialog.notify.error('No se ha podido crear el producto.', {
              position: 'bottom-right',
              timeout: 5000,
            })
            this.updateLoading = false;
            return;
          }
        } catch (error) {
          console.log(error);
          this.$dialog.notify.error(error.response.data, {
            position: 'bottom-right',
            timeout: 5000,
          })

          this.updateLoading = false;
          return;
        }
      } else {
        try {
          const updateResult = await services.products.updateProduct(this.$route.params.slug, this.selectedProduct);
          if (updateResult && updateResult.data && updateResult.data.id !== '') {
            this.selectedProduct = updateResult.data;
          } else {
            this.$dialog.notify.error('No se ha podido actualizar el producto.', {
              position: 'bottom-right',
              timeout: 5000,
            })
            this.updateLoading = false;
            return;
          }
        } catch (error) {
          this.$dialog.notify.error(error.response.data, {
            position: 'bottom-right',
            timeout: 5000,
          })
          this.updateLoading = false;
          return;
        }
      }

      // IMAGES UPLOAD
      const filesToUpload = [];
      filesToUpload.push(this.productsImages.image1.file);
      filesToUpload.push(this.productsImages.image2.file);
      filesToUpload.push(this.productsImages.image3.file);

      if (filesToUpload.length) {
        try {
          const uploadResult = await services.media.uploadProductsImages(this.selectedProduct.id, filesToUpload);
          const toUpload = filesToUpload.filter(i => i !== undefined);
          if (uploadResult && uploadResult.data.length === toUpload.length) {

          } else {
            this.$dialog.notify.error('No se han podido subir algunas imágenes al producto.', {
              position: 'bottom-right',
              timeout: 5000,
            })
          }
        } catch (error) {
          console.log(error);
          this.$dialog.notify.error(error.response.data, {
            position: 'bottom-right',
            timeout: 5000,
          })
        }
      }

      if (this.creatingProduct) {
        this.$dialog.notify.success('El producto fue adicionado satisfactoriamente.', {
          position: 'bottom-right',
          timeout: 5000,
        })
      } else {
        this.$dialog.notify.success('El producto fue actualizado satisfactoriamente.', {
          position: 'bottom-right',
          timeout: 5000,
        })
      }

      this.updateLoading = false;
      await this.$router.push({name: 'admin_business_list_products', params: {slug: this.$route.params.slug}});
    },

    /**
     * Delete the product.
     * @param product
     * @param event
     * @returns {Promise<void>}
     */
    deleteProduct: async function (event) {
      const res = await this.$dialog.warning({
        text: '¿ Realmente desea eliminar el producto ?',
        title: 'SeOferta',
        actions: {
          false: 'Cancelar',
          true: {
            text: 'Eliminar',
            color: 'primary'
          }
        },
        persistent: true,
        color: '#d3691f',
        icon: false
      });
      if (res !== true)
        return;

      this.deleteLoading = true;

      try {
        const result = await services.products.deleteProduct(this.$route.params.slug, this.selectedProduct);
        this.$dialog.notify.success('El producto fue eliminado correctamente.', {
          position: 'bottom-right',
          timeout: 5000,
        })

        this.requestLoading = false;
        await this.$router.push({name: 'admin_business_list_products', params: {slug: this.$route.params.slug}});
      } catch (e) {
        console.log(error);
        this.$dialog.notify.error('No se pudo eliminar el producto.', {
          position: 'bottom-right',
          timeout: 5000,
        })
        this.deleteLoading = false;
      }
      event.stopPropagation();
    },
    onFileChange(e) {
      if (e.target.files.length === 0)
        return;

      const file = e.target.files[0];

      // check file size
      const filesize = ((file.size / 1024) / 1024).toFixed(4);
      if(filesize > 8.0){
        this.$dialog.notify.warning('La foto seleccionada es demasiado grande.', {
          position: 'bottom-right',
          timeout: 5000,
        })
        return;
      }

      const extension = file.name.split('.').pop();
      if (extension.toUpperCase() === 'JFIF') {
        this.$dialog.notify.warning('La foto seleccionada no tiene el formato correcto.', {
          position: 'bottom-right',
          timeout: 5000,
        })
        return;
      }

      switch (e.target.name) {
        case 'file1': {
          this.productsImages.image1.file = file;
          this.productsImages.image1.url = URL.createObjectURL(file);
          this.dataChanged = true;
          break;
        }
        case 'file2': {
          this.productsImages.image2.file = file;
          this.productsImages.image2.url = URL.createObjectURL(file);
          this.dataChanged = true;
          break;
        }
        case 'file3': {
          this.productsImages.image3.file = file;
          this.productsImages.image3.url = URL.createObjectURL(file);
          this.dataChanged = true;
          break;
        }
      }
    },
    toggleCategoryDialog: function () {
      this.categoryDialog = !this.categoryDialog;
    }
  },
  watch: {
    selectedProduct: {
      handler() {
        if (this.dataMounted === true) {
          this.dataChanged = true
        }
      },
      deep: true
    }
  },
};
</script>
<style scoped>
.v-card.on-hover {
  border: 2px solid #00999a;
}

.v-btn:not(.v-btn--round).v-size--x-large {
  height: 56px;
}

.v-card {
  border: 2px solid rgba(0, 0, 0, 0.12);;
}

.v-icon.icon-hover {
  color: #00999a;
}

.v-icon {
  color: #9e9e9e;
}

</style>
